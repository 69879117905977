import { useStore } from "../../../../stores/store";

const useAssetOverviewHook = () => {
  const { AssetStore } = useStore();
  const asset = AssetStore.selectedAsset;
  return {
    asset,
  };
};

export default useAssetOverviewHook;
