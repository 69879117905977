import styled from "styled-components";

export const ImportModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .upload-area {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .upload {
      width: 300px;
      padding: 0 10px;
      border: 1px dashed lightgray;
    }
    .error {
      border-color: red;
      background-color: #ffb3b3;
    }
  }
  .btn-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }
  .error-msg {
    margin-top: 20px;
    color: red;
  }
  .parent-area {
    .field {
      width: 100%;
      min-width: 200px;
    }
  }
`;
