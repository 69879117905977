import { makeAutoObservable } from "mobx";
import { AssetCompleteDto } from "../models/dtos/AssetDtos";
import { services } from "../api/agent";
import { SelectOption } from "../models/types/SelectOptions";

export default class AssetStore {
  selectedAsset: AssetCompleteDto | undefined = undefined;
  treeUpdated: boolean = false;
  parentSelectOptions: SelectOption[] = [];

  setSelectedAsset = async (value: number | undefined) => {
    if (value) {
      const response = await services.assets.getById(value);
      const responseData = response.data;
      this.selectedAsset = responseData;
    } else {
      this.selectedAsset = undefined;
    }
  };

  setTreeUpdated = (updatedFlag: boolean) => {
    this.treeUpdated = updatedFlag;
  }

  setParentSelectOptions = async () => {
    const response = await services.assets.getAll(undefined, undefined, 'id,path')
    const responseData = response.data;
    const options = responseData.map(asset => new SelectOption(asset.id.toString(), asset.path)).sort((a, b) => a.label.localeCompare(b.label));;
    this.parentSelectOptions = options;
}

  constructor() {
    makeAutoObservable(this);
  }
}
