import React, { useState } from "react";
import Button from "@plasma/ui.general.button";
import {
  InnerToolbarContainer,
  MultipleAssetViewContainer,
  TableSettingsContainer,
  ToolbarContainer,
} from "./multipleAssetView.style";
import CustomTree from "./customTree/customTree";
import useMultipleAssetViewHook from "./multipleAssetView.hook";
import CreateAssetModal from "./create-asset-modal/createAssetModal";
import Popover from "@plasma/ui.display.popover";
import { List, ListItem } from "@plasma/ui.display.list";
import ImportModal from "./ImportModal/ImportModal";
import SearchInput from "@plasma/ui.input.search-input";
import { useTranslation } from "react-i18next";
import Secure from "@plasma/ui.application.secure"
import { debounce } from "lodash";

interface Props {
  selectedRows: Record<string, boolean> | undefined;
  setSelectedRows: (value: number | undefined) => void;
}

function MultipleAssetView(props: Props) {
  const {t} = useTranslation()
  const logic = useMultipleAssetViewHook();
  const [columSettingOpen, setColumSettingOpen] = useState(false);
  const [columnOptions, setColumOptions] = useState<
    ListItem<{ title: string; dataIndex: string }>[]
  >([
    { item: { title:"asset-tree.columns.code", dataIndex: "code" } },
    { item: { title:"asset-tree.columns.name", dataIndex: "name" } },
  ]);

  const [selectedColumns, setSelectedColumns] = useState([columnOptions[0].item, columnOptions[1].item]);

  const selectedElements = selectedColumns.reduce(
    (a, v) => ({ ...a, [v.dataIndex]: true }),
    {}
  );

  const ModalContent = () => {
    const [tempSelectedColumns, setTempSelectedColumns] = useState(selectedColumns);
    const [tempColumnOptions, setTempColumOptions] = useState(columnOptions);

    const handleColumCheck = (value: Record<string, boolean>) => {
      setTempSelectedColumns(
        tempColumnOptions
          .map((x) => x.item)
          .filter((x) => Object.keys(value).includes(x.dataIndex))
      );
    };

    const handleColumOrder = (
      value: ListItem<{ title: string; dataIndex: string }>[]
    ) => {
      setTempColumOptions(value);
      setTempSelectedColumns(
        value
          .map((x) => x.item)
          .filter((x) =>
            tempSelectedColumns.map((x) => x.dataIndex).includes(x.dataIndex)
          )
      );
    };

    const saveHandler = () => {
      setSelectedColumns(tempSelectedColumns);
      setColumOptions(tempColumnOptions);
      setColumSettingOpen(!columSettingOpen);
    };
    return (
      <TableSettingsContainer>
        <List
          style={{ width: 400 }}
          disablePagination
          canReorder
          canCheck
          checkedRowsIds={selectedElements}
          onCheckedRowsChange={(rows) => {
            handleColumCheck(rows);
          }}
          onRowsReorder={(rows) => {
            handleColumOrder(rows);
          }}
          dataSource={columnOptions}
          rowKey={(item) => item.dataIndex}
          rowId={(item) => item.dataIndex}
          renderItem={(item, key) => t(item.title)}
        />
        <div className="btn-container">
          <Button
            onClick={() => setColumSettingOpen(!columSettingOpen)}
            title={t("button.cancel")}
            type="secondary"
          />
          <Button
            onClick={() => {
              saveHandler();
            }}
            title={t("button.save")}
            type="primary"
          />
        </div>
      </TableSettingsContainer>
    );
  };

  return (
    <MultipleAssetViewContainer>
      <ToolbarContainer>
        <SearchInput
          onChange={debounce((value)=>{logic.setSearchValue(value.target.value)}, 300)}
          className="search-field"
          placeholder={t("asset-tree.search.placeholder")}
          kind="filled"
        />
      </ToolbarContainer>
      <ToolbarContainer>
        <Popover
          onVisibleChange={(v) => setColumSettingOpen(v)}
          destroyTooltipOnHide
          title={t("asset-tree.settings.header")}
          visible={columSettingOpen}
          placement="bottomLeft"
          content={
            <>
              <span>{t("asset-tree.settings.column.columns")}</span>
              <ModalContent />
            </>
          }
          trigger="click"
        >
          <Button
            onClick={() => setColumSettingOpen(!columSettingOpen)}
            title={t("button.settings")}
            type="secondary"
            suffixIcon={{ name: "expand_more" }}
          />
        </Popover>
        <Secure permissions={['editor']}>
        <InnerToolbarContainer>
          <Button
            title={t("button.import")}
            type="ghost"
            prefixIcon={{ name: "file_upload" }}
            onClick={() => {
              logic.setShowImportModal(true);
            }}
          />
          <Button
            title={t("button.create")}
            type="primary"
            prefixIcon={{ name: "Add" }}
            onClick={() => {
              logic.setCreateAssetModalOpened(true);
            }}
          />
        </InnerToolbarContainer>
        </Secure>
      </ToolbarContainer>
      <CustomTree
        searchValue={logic.searchValue}
        selectedColumns={selectedColumns}
        selectedRows={props.selectedRows}
        setSelectedRows={props.setSelectedRows}
      />
      <CreateAssetModal
        createAssetModalOpened={logic.createAssetModalOpened}
        setCreateAssetModalOpened={logic.setCreateAssetModalOpened}
      />
      <ImportModal
        open={logic.showImportModal}
        onCancel={() => logic.setShowImportModal(false)}
      />
    </MultipleAssetViewContainer>
  );
}

export default MultipleAssetView;
