import baseStyled, { ThemedStyledInterface } from "styled-components";

import { AssetTheme } from "../themes/types";

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const AssetContainer = styled.div`
  height: 100%;
  min-width: 332px;
  width: 80%;
`;

export const AssetMetadataContainer = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  .tabs {
    height: 0;
    flex-grow: 1;
    .ant-tabs-content {
      height: 100%;
      .tab-panel {
        overflow: auto;
        height: 100%;
      }
    }
  }
`;

export const AssetNotChosen = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
`;
