import React from "react";
import * as styled from "./IdentifierOverview.styles";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import Icon from "@plasma/ui.general.icon";
import Tooltip from "@plasma/ui.display.tooltip";
import { useIdentifierOverviewHook } from "./IdentifierOverview.hook";
import Secure from "@plasma/ui.application.secure";
import { useStore } from "../../../stores/store";


interface Props {
  onChange?: any;
  value?: Record<string, string | null>;
}

export default function IdentifierOverview(props: Props) {
  const { t } = useTranslation();
  const logic = useIdentifierOverviewHook(props);
  const { ExternalIdDefinitionStore } = useStore();

  return (
    <styled.IdentifierOverview>
      <div className="row">
        <div>
          <span>{t("asset-overview.tab.identification.label.identifier")}</span>
        </div>
        <div>
          <span>{t("asset-overview.tab.identification.label.value")}</span>
          <Tooltip title={t("asset-overview.tab.identification.hint.value")}>
            <Icon className="hint" name="help_outline" />
          </Tooltip>
        </div>
      </div>
      <div className="value-container">
        {ExternalIdDefinitionStore.externalIdDefinitions.map((element, index) => (
          <div className="row">
            <div>
              <span className="label">{`${element.name}${element.required ? " *" : ""}`}</span>
            </div>
            <div>
              <Secure permissions={["editor"]}>
                <Input
                  disabled={!element.editable}
                  className="input"
                  onChange={(e) =>
                    logic.valueChangeHandler(element.name, e.target.value)
                  }
                  value={logic.params[element.name] ?? ""}
                />
              </Secure>
              <Secure permissions={["viewer"]}>
                <Input
                  readOnly
                  className="input"
                  onChange={(e) =>
                    logic.valueChangeHandler(element.name, e.target.value)
                  }
                  value={logic.params[element.name] ?? ""}
                />
              </Secure>
            </div>
          </div>
        ))}
      </div>
    </styled.IdentifierOverview>
  );
}
