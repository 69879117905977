import React, { Dispatch } from "react";

import Form from '@plasma/ui.input.form'
import FormField from '@plasma/ui.input.form-field';
import TextInput from '@plasma/ui.input.text-input';
import TextArea from '@plasma/ui.input.text-area';
import Button from '@plasma/ui.general.button';

import { AssetCompleteDto } from "../../../../../models/dtos/AssetDtos";
import { AssetGeneralContainer, ButtonContainer } from "./assetGeneral.styles";
import useAssetGeneralHook from "./assetGeneral.hook";
import { checkObjectsAreEqual } from "../../../../../utils/objectsEqualityChecker";
import { useTranslation } from "react-i18next";
import Secure from "@plasma/ui.application.secure";

interface Props {
    chosenAssetId: number
}

function AssetGeneralMetadata(props: Props) {
    const logic = useAssetGeneralHook(props.chosenAssetId);
    const {t} = useTranslation()
    
    return (
        <AssetGeneralContainer>
            <Secure permissions={["editor"]}>
            <Form
                initialValues={logic.initialAsset}
                key={logic.formKey}
                onChange={ (data: Partial<AssetCompleteDto>) => { logic.handleSetUpdatedAsset(data);
            } }
                >
                <FormField required label={t("asset-overview.tab.general.label.name")} name="name" hint={t("asset-overview.tab.general.hint.name")}>
                    <TextInput required/>
                </FormField>
                <FormField label={t("asset-overview.tab.general.label.description")} name="description" hint={t("asset-overview.tab.general.hint.description")}>
                    <TextArea rows={5}/>
                </FormField>
                <FormField required label={t("asset-overview.tab.general.label.code")} name="code" hint={t("asset-overview.tab.general.hint.code")}>
                    <TextInput required/>
                </FormField>
            </Form>
            </Secure>
            <Secure permissions={["viewer"]}>
            <Form
                initialValues={logic.initialAsset}
                key={logic.formKey}
                >
                <FormField required label={t("asset-overview.tab.general.label.name")} name="name" hint={t("asset-overview.tab.general.hint.name")}>
                    <TextInput readOnly required/>
                </FormField>
                <FormField label={t("asset-overview.tab.general.label.description")} name="description" hint={t("asset-overview.tab.general.hint.description")}>
                    <TextArea readOnly rows={5}/>
                </FormField>
                <FormField required label={t("asset-overview.tab.general.label.code")} name="code" hint={t("asset-overview.tab.general.hint.code")}>
                    <TextInput  readOnly required/>
                </FormField>
            </Form>
            </Secure>
            <Secure permissions={["editor"]}>
            <ButtonContainer>
                {
                    checkObjectsAreEqual(logic.initialAsset ?? {}, logic.updatedAsset ?? {}) ? (
                        <>
                            <Button.Save title={t("button.save")} disabled/>
                            <Button.Cancel title={t("button.cancel")} disabled/>
                        </>
                    ) : (
                        <>
                            <Button.Save title={t("button.save")} onClick={() => {logic.onUpdateAsset(logic.updatedAsset!)}}/>
                            <Button.Cancel title={t("button.cancel")} type="secondary" onClick={() => {logic.onCancelChanges()}}/>
                        </>
                    )
                }
            </ButtonContainer>
            </Secure>
        </AssetGeneralContainer>
    )
}

export default AssetGeneralMetadata;