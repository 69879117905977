import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import Button from "@plasma/ui.general.button";
import Icon from '@plasma/ui.general.icon';
import Tooltip from '@plasma/ui.display.tooltip';
import TextInput from "@plasma/ui.input.text-input";


import { AssetTheme } from '../../themes/types';

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const AssetIdentificationContainer = styled.div`
  padding: 16px;
  min-width: 480px;
  width: 35%;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const LabelsContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  gap: 16px;
`

export const ProviderValuePairContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const ProviderContainer = styled.div`
  display: flex;  
  align-items: center;
  min-width: 224px;
  width: calc(50vh - 32px);
`

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 224px;
  width: calc(50vh - 32px);
`

export const AddNewFieldButton = styled(Button)`
  margin-bottom: 16px;
`

export const StyledTooltip = styled(Tooltip)`
  margin-left: 2px;
`

export const StyledHelperIcon = styled(Icon)`
  &.plasma-icon {
    color: #B2B2B2;
  }
`

export const StyledIdentificationTextInput = styled(TextInput)`
  min-width: 224px;
  width: calc(50vh - 32px);
`

