import styled from "styled-components";

export const IdentifierOverview = styled.div`
  width: 100%;
  .row {
    width: 100%;
    gap: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        flex-basis: 0;
    }

    .input {
      min-width: 0;
      flex-grow: 1;
      flex-basis: 0;
    }
  }
  .hint{
    cursor:help ;
    color:#B2B2B2;
  }

  .value-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
