import React, { Dispatch } from "react";

import Tabs, { CustomTabPane } from "@plasma/ui.navigation.tabs";

import {
  AssetContainer,
  AssetMetadataContainer,
  AssetNotChosen,
} from "./assetOverview.styles";
import useAssetOverviewHook from "./assetOverview.hook";
import AssetGeneralMetadata from "./asset-general/assetGeneral";
import AssetRelationMetadata from "./asset-relation/assetRelation";
import AssetIdentificationMetadata from "./asset-identification/assetIdentification";
import { Icon } from "@plasma/ui.general.icon";
import AssetHeader from "./asset-header/assetHeader";
import { useTranslation } from "react-i18next";

interface Props {
  selectedRows: Record<string, boolean>;
}

function AssetOverview(props: Props) {
  const { t } = useTranslation();
  const logic = useAssetOverviewHook();

  return (
    <AssetContainer>
      {logic.asset?.id ? (
        <AssetMetadataContainer>
          <AssetHeader/>
          <Tabs className="tabs" type="line" destroyInactiveTabPane={true}>
            <Tabs.TabPane
                     className="tab-panel"
              tab={
                <CustomTabPane
                  icon={<Icon name="Sell" variant="filled" />}
                  title={t("asset-overview.tab.general.title")}
                />
              }
              key={1}
            >
              <AssetGeneralMetadata
                key={logic.asset?.id}
                chosenAssetId={logic.asset?.id}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
                     className="tab-panel"
              tab={
                <CustomTabPane icon={<Icon name="Link" />} title={t("asset-overview.tab.relation.title")} />
              }
              key={2}
            >
              <AssetRelationMetadata
                key={logic.asset?.id}
                chosenAssetId={logic.asset?.id}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
            className="tab-panel"
              tab={
                <CustomTabPane
                  icon={<Icon name="Key" />}
                  title={t("asset-overview.tab.identification.title")}
                />
              }
              key={3}
            >
              <AssetIdentificationMetadata
                key={logic.asset?.id}
                chosenAssetId={logic.asset?.id}
              />
            </Tabs.TabPane >
          </Tabs>
        </AssetMetadataContainer>
      ) : (
        <AssetNotChosen>
          <p>
            <strong>{t("asset-overview.no-item.title")}</strong>
            <br />
            {t("asset-overview.no-item.description")}
          </p>
        </AssetNotChosen>
      )}
    </AssetContainer>
  );
}

export default AssetOverview;
