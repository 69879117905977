import { useState } from "react";
import { useStore } from "../../../stores/store";

interface Props {
  onChange?: any;
  value?: Record<string, string | null>;
}
export const useIdentifierOverviewHook = (props: Props) => {
  const { ExternalIdDefinitionStore } = useStore();

  const initial = ExternalIdDefinitionStore.externalIdDefinitions.reduce(
    (acc, item) => {
      acc[item.name] = null;
      return acc;
    },
    {} as Record<string, null>
  );

  const [internalParams, setInternalParams] = useState<
    Record<string, string | null>
  >(props.value ?? initial);

  const params = props.value ?? internalParams;
  const setState = (state: Record<string, string | null>) => {
    if (props.onChange) {
      props.onChange(state);
    }
    if (!props.value) {
      setInternalParams(state);
    }
  };

  const valueChangeHandler = (key: string, value: string | null) => {
    const newObject = { ...params };
    newObject[key] = value;
    setState(newObject);
  };

  return {
    valueChangeHandler,params
  };
};
