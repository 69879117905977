import { useEffect } from "react";
import { useStore } from "../../stores/store";

const useOverviewHook = () => {
    const { AssetStore } = useStore();
    const { ExternalIdDefinitionStore } = useStore();

    useEffect(() => {
      if (ExternalIdDefinitionStore.definitionsUpdated || ExternalIdDefinitionStore.externalIdDefinitions.length == 0) {
        ExternalIdDefinitionStore.setExternalIdDefinitions();
        ExternalIdDefinitionStore.setDefinitionsUpdated(false);
      }
    }, [])

    useEffect(() => {
        AssetStore.setParentSelectOptions();
    }, [AssetStore.treeUpdated])

};

export default useOverviewHook;
