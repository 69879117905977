import { ListItem } from "@plasma/ui.display.list";
import React, { useState } from "react";
import { AssetCompleteDto } from "../../../models/dtos/AssetDtos";
import { Icon } from "@plasma/ui.general.icon";
import * as styled from "./ListTable.styles";
import { useTranslation } from "react-i18next";
import Button from "@plasma/ui.general.button";
import Secure from "@plasma/ui.application.secure"
import Tooltip from "@plasma/ui.display.tooltip";

interface ListTableProps {
  selectedElement: number | undefined;
  onSelect: (id: string) => void;
  onExpand: (expandedItemIds: string[]) => Promise<void>;
  onDelete: () => void;
  items: ListItem<AssetCompleteDto>[];
  columns: {
    title: string;
    dataIndex: string;
  }[];
}
const ListTable = (props: ListTableProps) => {
  const { t } = useTranslation();
  const selectHandler = (x: string) => {
    if (openKeys.includes(x)) {
      const newArray = [...openKeys].filter((id) => id !== x);
      props.onExpand(newArray);
      setOpenKeys(newArray);
    } else {
      const newArray = [x, ...openKeys];
      props.onExpand(newArray);
      setOpenKeys(newArray);
    }
  };

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  return (
    <styled.ListTable>
      <table cellPadding="5" cellSpacing="0">
        <thead>
          <tr>
            {props.columns.map((x, index) => (
              <th key={index}>
                <div className="column-container"> {t(x.title)}</div>
              </th>
            ))}
            <th className="action-cell" />
          </tr>
        </thead>
        <tbody>
          {props.items.map((row, index) => (
            <RowEelement
              onDelete={props.onDelete}
              onSelect={props.onSelect}
              selectedElement={props.selectedElement}
              level={0}
              onElementClick={(x) => selectHandler(x)}
              key={index}
              columns={props.columns}
              element={row}
              selectedId={openKeys}
            />
          ))}
        </tbody>
      </table>
    </styled.ListTable>
  );
};

export default ListTable;

interface RowElementProps {
  selectedElement: number | undefined;
  level: number;
  onSelect: (id: string) => void;
  selectedId: string[];
  onDelete: () => void;
  onElementClick?: (id: string) => void;
  element: ListItem<AssetCompleteDto>;
  columns: {
    title: string;
    dataIndex: string;
  }[];
}

const RowEelement = (props: RowElementProps) => {
  const onExpand = (id: string) => {
    if (props.onElementClick) props.onElementClick(id);
  };
  const selected = props.selectedId.includes(props.element.item.id.toString());
  const hasChildren = (props.element.subRows?.length ?? 0) > 0;
  const isSelected =
    props.selectedElement && props.selectedElement === props.element.item.id;
  const { t } = useTranslation();
  
  return (
    <>
      <tr
        onClick={() => {
          props.onSelect(props.element.item.id.toString());
        }}
        className="list-row"
      >
        {props.columns.map((x, index) =>
          index === 0 ? (
            <td
              className={`data-cell ${isSelected ? "selected" : ""}`}
              key={index}
            >
              <div
                style={{
                  marginLeft: props.level * 20 + (!hasChildren ? 30 : 0),
                }}
                className="first-child"
              >
                {hasChildren ? (
                  <Icon
                    onClick={(e) => {
                      e.stopPropagation();
                      onExpand(props.element.item.id.toString());
                    }}
                    className={"clickable"}
                    name={selected ? "expand_more" : "navigate_next"}
                  />
                ) : null}
                <div className="clickable">
                  {props.element.item[x.dataIndex as keyof AssetCompleteDto]}
                </div>
              </div>
            </td>
          ) : (
            <td
              className={`data-cell ${isSelected ? "selected" : ""}`}
              key={index}
            >
              <div className="no-children">
                {props.element.item[x.dataIndex as keyof AssetCompleteDto]}
              </div>
            </td>
          )
        )}
        <td className={`action-cell ${isSelected ? "selected" : ""}`}>
          {isSelected ? (
            <Secure permissions={['editor']}>
              <Tooltip title={t("icon.delete-asset.hint")}>
                <Button
                  type="text"
                  prefixIcon={{ name: "delete", variant: "filled" }}
                  onClick={() => props.onDelete()}
                />
              </Tooltip>

            </Secure>
           
          ) : (
            <Button type="text" />
          )}
        </td>
      </tr>
      {selected
        ? props.element.subRows?.map((subElement) => (
            <RowEelement
              onDelete={props.onDelete}
              onSelect={props.onSelect}
              selectedElement={props.selectedElement}
              level={props.level + 1}
              onElementClick={props.onElementClick}
              selectedId={props.selectedId}
              columns={props.columns}
              element={subElement}
            />
          ))
        : null}
    </>
  );
};
