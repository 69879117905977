export const createAssetQueryString = (keys: string[], value: string | undefined) => {
  let output: string | undefined = undefined;
  if (value && value.length > 0) {
    output = "";
    keys.forEach((key, ii) => {
      if (ii === 0) {
        output = output + `${key} contains '${value}'`;
      } else {
        output = output + ` or ${key} contains '${value}'`;
      }
    });
  }
  return output;
};
