import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import {Icon} from '@plasma/ui.general.icon'

import TextInput from '@plasma/ui.input.text-input';

import { AssetTheme } from '../../themes/types';

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const NotEditableTextInput = styled(TextInput)`
  && {
    input {
      background-color: #E5E5E5 !important;
    }
  }
`;



export const AssetRelationContainer = styled.div`
  padding: 16px;
  min-width: 480px;
  width: 35%;
  .parent-select{
    width: 100%;
  }
`

export const ClearIcon = styled(Icon)`
  && {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #0775BC;
    font-size: 22px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`
