import React from 'react';
import { Route } from '@plasma/ui.application.router';
import { OverviewRoutes } from '../../../pages/overview';

const Layout = React.lazy(() => import('.'));

export const LayoutRoutes = new Route({
  path: '',
  element: Layout,
  children: [
    ...OverviewRoutes
  ],
});
