import { useEffect, useMemo, useState } from "react";
import { services } from "../../../../../api/agent";
import { AssetCompleteDto } from "../../../../../models/dtos/AssetDtos";
import { ListItem } from "@plasma/ui.display.list";
import { createPathEqualsQuery } from "../../../../../utils/queryCreators";
import { createAssetQueryString } from "../../../../../utils/createAssetQueryString";
import { useStore } from "../../../../../stores/store";

const useCustomTreeHook = (searchValue:string) => {
    const { AssetStore } = useStore();
    const [originalAssets, setOriginalAssets] = useState<AssetCompleteDto[]>([]);
    const [assets, setAssets] = useState<AssetCompleteDto[]>([]);
    const [assetsWithLoadedChildren, setAssetsWithLoadedChildren] = useState<string[]>([]);
    const [collapsedTree, setCollapsedTree] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [showAssetDeleteModal, setShowAssetDeleteModal] = useState(false);
    const defaultSearchLevel = 2;

    const fetchOverviewData = async ({ level, ancestorQuery }: { level?: number; ancestorQuery?: string }) => {
        try {
            const assetsResponse = await services.assets.getAll(level, ancestorQuery);
            const assetsData = assetsResponse.data;
            setAssets(prevAssets => {
                const assetMap = new Map(prevAssets.map(asset => [asset.id, asset]));
                assetsData.forEach(asset => assetMap.set(asset.id, asset));
                return Array.from(assetMap.values());
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            setInitialLoading(false)
        }
    }

    const clearAssets = () => {
        setAssets([]);
        setAssetsWithLoadedChildren([]);
        setOriginalAssets([]);
        setCollapsedTree(true);
    };

    useEffect(() => {
        fetchOverviewData({level: defaultSearchLevel, ancestorQuery: createAssetQueryString(["name", "code", "description"], searchValue)});
    }, []);

    useEffect(() => {
        if (AssetStore.treeUpdated) {
            clearAssets();
            fetchOverviewData({ level: defaultSearchLevel, ancestorQuery: createAssetQueryString(["name", "code", "description"], searchValue) }).then(() => {
                AssetStore.setTreeUpdated(false);
            });
        }
    }, [AssetStore.treeUpdated, AssetStore.setTreeUpdated]);

    const buildAssetHierarchy = (assets: AssetCompleteDto[]) => {
        const nodeMap: { [key: string]: ListItem<AssetCompleteDto> } = {};
        const result: ListItem<AssetCompleteDto> [] = [];
        const topLevel2 = Math.min(...assets.map(a=>a.path.split('.').length));
        assets.forEach(asset => {
            nodeMap[asset.path] = {item: asset, subRows: []};
        })

        assets.forEach(asset => {
            const node = nodeMap[asset.path]
            const relationLevel = asset.path.split('.').length;

            let lastDotindex = asset.path.lastIndexOf('.');
            let prevPath = asset.path.substring(0, lastDotindex);

            if (relationLevel > topLevel2 && assets.find(x=>x.path==prevPath) !== undefined ) {
                const parentPath = asset.path.split('.').slice(0, -1).join('.');
                const parentNode = nodeMap[parentPath];
                if (parentNode?.subRows) {
                    parentNode.subRows.push(node);
                }
            } else {
                result.push(node);
            }

        })
        return result;
    };

    const assetHierarchy = useMemo(() => {return buildAssetHierarchy(assets)}, [assets]);

    useEffect(()=>{
        const onSearchAsset =  async(value:string) =>{
            if (value.length>0) {
                //TODO: Replace hardcoded keys with selected keys in v2
                var response = await services.assets.getAll(undefined, createAssetQueryString(["name", "code", "description"], value));
                if (originalAssets.length == 0) {
                    setOriginalAssets(assets);
                }
                setAssets(response.data);
            } else {
                setAssets(originalAssets);
            }
        }
        onSearchAsset(searchValue)
    }, [searchValue])


    const onExpandAsset = async (expandedItemIds: string[]) => {
        setCollapsedTree(false);
        let ancestorQuery = undefined;

        for (const expandedItemId of expandedItemIds) {

            if (!assetsWithLoadedChildren.includes(expandedItemId)) {
                const expandedItem = assets.find(asset => asset.id === Number(expandedItemId));

                if (expandedItem) {
                    ancestorQuery = createPathEqualsQuery(expandedItem.path);
                    await fetchOverviewData({level: defaultSearchLevel, ancestorQuery: ancestorQuery});
                    setAssetsWithLoadedChildren(prevAssets => [...prevAssets, expandedItemId]);
                }
            }
        }
    };

    return {assets, assetHierarchy, onExpandAsset, collapsedTree, initialLoading, showAssetDeleteModal, setShowAssetDeleteModal};
};

export default useCustomTreeHook;
