import baseStyled, { ThemedStyledInterface } from "styled-components";

import { AssetTheme } from "../themes/types";

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const MultipleAssetViewContainer = styled.div`
  min-width: 447px;
  width: 40%;
  border-right: 1px solid #d9d9d9;
  background-color: #f2f2f2;
  height: 100%;
  display: flex;
  flex-direction: column;
  .search-field {

  }
`;

export const InnerToolbarContainer = styled.div`
  height: 48px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

export const ToolbarContainer = styled.div`
  height: 48px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;
export const TableSettingsContainer = styled.div`
  .btn-container {
    justify-content: right;
    gap: 10px;
    display: flex;
    flex-direction: row;
  }
`;
