import React from 'react';
/*
To import child routes, insert the following code:
import { routes as <child-folder>Routes } from './components/<folder>';
*/

import { Route } from '@plasma/ui.application.router';

const Overview = React.lazy(() => import('.'));

export const OverviewRoutes = [
  new Route({
    path: '/assets',
    element: Overview,
  }),
];
