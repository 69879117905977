import { useMemo, useState } from "react";
import { AssetToCreate } from "../../../../../models/types/AssetToCreate";
import { AssetDto } from "../../../../../models/dtos/AssetDtos";
import { services } from "../../../../../api/agent";
import objectValidator from "../../../../../utils/objectValidator";
import { notification } from "antd";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { useStore } from "../../../../../stores/store";

const useCreateAssetModalHook = () => {
  const { AssetStore } = useStore();
  const { ExternalIdDefinitionStore } = useStore();
  const [assetToCreate, setAssetToCreate] = useState<AssetToCreate>();
  const { t } = useTranslation();

  const onCreateAsset = async (data: AssetToCreate) => {
    const arrayOfAssets = buildAssetDto(data);
    await services.assets
      .create(arrayOfAssets)
      .then(() => {
        notification.success({
          message: t("create-asset.notification.title.success"),
          description: (
            <Trans
              i18nKey={"create-asset.notification.message.success"}
              values={{ name: assetToCreate?.name }}
              components={{ strong: <strong /> }}
            />
          ),
        });
        AssetStore.setTreeUpdated(true);
      })
      .catch(() => {
        notification.error({
          message: t("create-asset.notification.title.failed"),
        });
      });
  };

  const objectValid = useMemo(() => {
    return objectValidator(
      assetToCreate?.externalIds ?? {},
      ExternalIdDefinitionStore.externalIdDefinitions.filter((e) => e.required).map((e) => e.name)
    );
  }, [assetToCreate]);

  const buildAssetDto = (formData: AssetToCreate) => {
    let dataToSave: AssetDto[] = [];
    let path = formData.code;
    if (formData.parent) {
      const parentPath = getParentPathById(formData.parent)!;
      path = parentPath + "." + formData.code;
    }
    dataToSave.push(
      new AssetDto({
        name: formData.name,
        code: formData.code,
        path: path,
        description: formData.description,
        externalIds: formData.externalIds,
      })
    );

    return dataToSave;
  };

  const getParentPathById = (parentId: number) => {
    const selectedOption = AssetStore.parentSelectOptions.find(
      (option) => option.value === parentId.toString()
    );
    return selectedOption?.label;
  };

  return {
    onCreateAsset,
    assetToCreate,
    setAssetToCreate,
    objectValid,
  };
};

export default useCreateAssetModalHook;
