import styled from "styled-components";

export const ListTable = styled.div`
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  table {
    min-width: 100%;
    border-collapse: separate;
  }
  thead {
    position: sticky;
    top: 0px;
    z-index: 1;
    border-bottom: 1px solid #b8b8b8;
    background-color: #f2f2f2;

    th:not(:last-child) {
      .column-container {
        border-right: 1px solid #b8b8b8;
      }
    }
  }
  th {
    border-bottom: 1px solid #b8b8b8;
  }

  th,
  td {
    padding: 8px;
    padding-left: 16px;
    text-align: left;
    &.data-cell {
      cursor:default;
    }
    &.action-cell {
      padding: 0px;
      width: 50px;
    }
    &.selected {
      background-color: #dce9f9;
      border-color: #7fb0e7;
    }
  }

  td {
    border-bottom: 1px solid #b8b8b8;
  }

  .list-row {

    td {
      .first-child {
        display: flex;
        gap: 10px;
        align-items: center;
        .clickable {
        }
      }
    }
  }
  .list-row:hover{
    background-color:#f3f5f7;
  }
`;
