import { useEffect, useMemo, useState } from "react";

import { services } from "../../../../../api/agent";
import { AssetCompleteDto } from "../../../../../models/dtos/AssetDtos";
import { createPathEqualsQuery } from "../../../../../utils/queryCreators";
import { FormData } from "../../../../../models/types/AssetRelation";
import { buildParentPath } from "../../../../../utils/parentPathBuilder";
import { SelectOption } from "../../../../../models/types/SelectOptions";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";
import { checkObjectsAreEqual } from "../../../../../utils/objectsEqualityChecker";

const useAssetRelationHook = (chosenAssetId: number) => {
    const { AssetStore } = useStore();
    const [chosenAsset, setChosenAsset] = useState<AssetCompleteDto>();
    const [selectedOption, setSelectedOption] = useState<FormData>();
    const [initialParent, setInitialParent] = useState<string>();
    const [parentToUpdate, setParentToUpdate] = useState<string>();
    const [assetDirectChildren, setAssetDirectChildren] = useState<AssetCompleteDto[]>();
    const [parentCleared, setParentCleared] = useState<boolean>(false)
    const [sufficientParentSelectOptions, setSufficientParentSelectOptions] = useState<SelectOption[]>();
    const defaultLevel = 1;
    const {t} = useTranslation();
    
    const fetchSingleAsset = async (id: number) => {
        const response = await services.assets.getById(id);
        return response.data;
    };

    // TODO use select param to fetch only needed fields
    const fetchChildren = async (level: number, ancestorAsset: AssetCompleteDto) => {
        let ancestorQuery = createPathEqualsQuery(ancestorAsset.path);
        const response = await services.assets.getAll(level, ancestorQuery);
        const responseData = response.data;
        return responseData.filter(item => item.path !== ancestorAsset.path);
    };

    // TODO use select param to fetch only needed fields
    const fetchAndSetDirectChildren = async (ancestorAsset: AssetCompleteDto) => {
        const directChildren = await fetchChildren(defaultLevel, ancestorAsset);
        setAssetDirectChildren(directChildren);
    };

    const excludeUnsufficientParentOptions = () => {
        const suffucientOptions = AssetStore.parentSelectOptions.filter(p => p.value !== chosenAssetId.toString())
        setSufficientParentSelectOptions(suffucientOptions);
    };

    useEffect(() => {
        setParentCleared(false);
    
        fetchSingleAsset(chosenAssetId)
          .then(asset => {
            setChosenAsset(asset);
            return asset;
          })
          .then(asset => {
            const parentPath = buildParentPath(asset);
            setInitialParent(parentPath);
            setParentToUpdate(parentPath);
            return fetchAndSetDirectChildren(asset);
          })
          .then(() => {
            excludeUnsufficientParentOptions();
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);

    const onCancelChanges = () => {
        setParentToUpdate(initialParent);
        setParentCleared(false);
        setSelectedOption(new FormData());
    };

    // TODO: provide proper solution instead of this workaround with the next release
    const handleOnChange = (data: Partial<FormData>) => {
        if (data.parent) {
            const parentPath = getParentPathById(data.parent!)
            setParentToUpdate(parentPath);
            setSelectedOption(new FormData(data.parent));
        }
    };

    const objectChanged = useMemo(() => {
        return !checkObjectsAreEqual(initialParent ?? "", parentToUpdate ?? "");
      }, [initialParent, parentToUpdate]);

    const onClearParent = () => {
        setParentCleared(true);
        setParentToUpdate('');
        setSelectedOption(new FormData());
    }

    const onUpdateParent = async (parentToUpdate: string) => {
        await handleRelatedAssetsUpdate(parentToUpdate, chosenAsset!)
        .then((response) => {
            notification.success({message:t("asset-overview.tab.relation.notification.title.success")});
            setInitialParent(response);
            setParentToUpdate(response);
            setParentCleared(false);
            AssetStore.setTreeUpdated(true);
          })
          .catch((e) => notification.error({message:t("asset-overview.tab.relation.notification.title.failed")}))
          .finally(() => {
 
          });
       
    }

    const handleRelatedAssetsUpdate = async (parentToUpdate: string, initialAsset: AssetCompleteDto) => {
        const updatedAsset = await handleChosenAssetUpdate(parentToUpdate, initialAsset);
        const updatedParent = updatedAsset.path.split('.').slice(0, -1).join('.');
        return updatedParent;
    }

    const handleChosenAssetUpdate = async (parentToUpdate: string, initialAsset: AssetCompleteDto) => {
        const assetWithNewPath = buildAssetWithNewPath(parentToUpdate, initialAsset);
        const {id, ...assetToUpdated} = assetWithNewPath;
        const updateResponse = await services.assets.update(id, assetToUpdated);
        return updateResponse.data as AssetCompleteDto;
    }

    const getParentPathById = (parentId: number) => {
        const selectedOption = sufficientParentSelectOptions?.find(option => option.value === parentId?.toString());
        return selectedOption?.label;
    }

    const buildAssetWithNewPath = (newParent: string, originalAsset: AssetCompleteDto) => {
        let originalParentLength: number;
        const originalParent = originalAsset.path.split('.').slice(0, -1);
        if (originalParent) {
            originalParentLength = originalParent?.length;
        } else {
            originalParentLength = 0;
        }

        const assetCode = originalAsset.path.split('.').slice(originalParentLength).join('.');

        let newPath: string;
        if (newParent) {
            newPath = newParent + '.' + assetCode;
        } else {
            newPath = assetCode;
        }
        
        return new AssetCompleteDto({
            id: originalAsset.id, 
            name: originalAsset.name, 
            code: originalAsset.code, 
            path: newPath, 
            description: originalAsset.description, 
            externalIds: originalAsset.externalIds
        });
    }

    return {
        onUpdateParent,
        parentToUpdate,
        initialParent,
        setParentToUpdate,
        onCancelChanges,
        handleOnChange,
        onClearParent,
        parentCleared,
        assetDirectChildren,
        sufficientParentSelectOptions,
        objectChanged,
        selectedOption
    };

};

export default useAssetRelationHook;
