import { useEffect, useMemo, useState } from "react";
import { services } from "../../../../../api/agent";
import { checkObjectsAreEqual } from "../../../../../utils/objectsEqualityChecker";
import objectValidator from "../../../../../utils/objectValidator";
import { AssetCompleteDto } from "../../../../../models/dtos/AssetDtos";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";

const useAssetRelationHook = (chosenAssetId: number) => {
  const { ExternalIdDefinitionStore } = useStore();
  const [isUpdating, setIsUpdating] = useState(false);
  const [initialValues, setInitialValues] = useState<AssetCompleteDto>();
  const [values, setValues] = useState<Record<string, string | null>>({});
  const { t } = useTranslation();
  
  const fetchSingleAsset = async (id: number) => {
    await services.assets
      .getById(id)
      .then((response) => {
        setInitialValues({ ...response.data });
        setValues({ ...response.data.externalIds });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchSingleAsset(chosenAssetId);
  }, []);

  const changeHandler = (value: Record<string, string | null>) => {
    setValues({ ...value });
  };
  
  const objectChanged = useMemo(() => {
    return !checkObjectsAreEqual(initialValues?.externalIds ?? {}, values);
  }, [values, initialValues]);

  const objectValid = useMemo(() => {
    return objectValidator(
      values,
      ExternalIdDefinitionStore.externalIdDefinitions.filter((e) => e.required).map((e) => e.name)
    );
  }, [values, initialValues]);

  const onCancel = () => {
    setValues({ ...initialValues?.externalIds });
  };

  const updateIdentification = async () => {
    const { id, ...asset } = { ...initialValues! };
    asset.externalIds = values;
    setIsUpdating(true);
    await services.assets
      .update(id, asset)
      .then((response) => {
        notification.success({message:t("asset-overview.tab.identification.notification.title.success")});
        fetchSingleAsset(chosenAssetId);
      })
      .catch((e) => notification.error({message:t("asset-overview.tab.identification.notification.title.failed")}))
      .finally(() => setIsUpdating(false));
  };

  return {
    updateIdentification,
    onCancel,
    changeHandler,
    values,
    objectChanged,
    isUpdating,
    objectValid,
  };
};

export default useAssetRelationHook;
