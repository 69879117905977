import { useState } from "react";
import { services } from "../../../../../api/agent";
import { UploadFile } from "antd/lib/upload/interface";
import { notification as notificationant } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";

const useImportModalHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [pspFile, setPspFile] = useState<UploadFile<any> | null>(null);
  const [pbsFile, setPbsFile] = useState<UploadFile<any> | null>(null);
  const [parentId, setParentId] = useState<string>();
  const allowUpload = pbsFile !== null && pspFile !== null;
  const {t} = useTranslation()
  const { AssetStore } = useStore();

  const selectParentHandler = (value: string | undefined) => {
    value ? setParentId(value) : setParentId(undefined);
  };

  const handlePSPFileUpload = (file: any) => {
    setError(false);
    setPspFile(file);
    return false;
  };

  const handlePBSFileUpload = (file: any) => {
    setError(false);
    setPbsFile(file);
    return false;
  };

  const handleUpload = async () => {
    setError(false);
    setLoading(true);
    if (!pspFile || !pbsFile) {
      console.error("Please upload both TOS and PBS files.");
      return;
    }

    const formData = new FormData();
    formData.append("tosExportFile", pspFile as any);
    formData.append("pbsExportFile", pbsFile as any);
    if (parentId !== undefined) {
      formData.append("parentId", parentId);
    }

    await services.assets
      .import(formData)
      .then((response) => {
        setError(false);
        notificationant.success({
          message: t("import-asset.notification.title.success"),
          description: t("import-asset.notification.message.success"),
        });
        AssetStore.setTreeUpdated(true);
      })
      .catch((error) => {
        setError(true);
        notificationant.error({
          message: t("import-asset.notification.title.failed"),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setFileStatus = (file: UploadFile<any>) => {
    if (error) file.status = "error";
    else file.status = "done";
    return file;
  };

  return {
    loading,
    error,
    pspFile,
    pbsFile,
    setError,
    handlePBSFileUpload,
    handlePSPFileUpload,
    handleUpload,
    allowUpload,
    selectParentHandler,
    setFileStatus,
  };
};

export default useImportModalHook;
