import React from "react";
import Button from "@plasma/ui.general.button";
import useAssetIdentificationHook from "./assetIdentification.hook";
import {
  AssetIdentificationContainer,
  ButtonContainer,
} from "./assetIdentification.styles";
import { useTranslation } from "react-i18next";
import IdentifierOverview from "../../../../shared/IdentifierOverview/IdentifierOverview";
import Secure from "@plasma/ui.application.secure"

interface Props {
  chosenAssetId: number;
}

function AssetIdentificationMetadata(props: Props) {
  const logic = useAssetIdentificationHook(props.chosenAssetId);
  const { t } = useTranslation();
  return (
    <AssetIdentificationContainer>
      <IdentifierOverview
        onChange={logic.changeHandler}
        value={logic.values}
      />
      <Secure permissions={['editor']}>
      <ButtonContainer>
        <>
          <Button.Save
            disabled={!(logic.objectChanged && logic.objectValid)}
            loading={logic.isUpdating}
            title={t("button.save")}
            onClick={logic.updateIdentification}
          />
          <Button.Cancel
            disabled={!logic.objectChanged}
            title={t("button.cancel")}
            type="secondary"
            onClick={logic.onCancel}
          />
        </>
      </ButtonContainer>
      </Secure>
    </AssetIdentificationContainer>
  );
}

export default AssetIdentificationMetadata;
