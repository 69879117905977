import axios from 'axios';

import { AssetDto, AssetCompleteDto } from '../models/dtos/AssetDtos';
import { ExternalIdDefinitionDto } from '../models/dtos/ExternalIdDefinitionDtos';

const axiosInstance = axios.create();

//@ts-ignore
axiosInstance.defaults.baseURL = window.env.BACKEND_URL;

const apiVersion = '1.1';

const assets = {
    getAll: (level?: number, ancestor?: string, select?: string) => {
        const params = new URLSearchParams();

        if (level !== undefined) {
            params.append('$level', level.toString());
        }
        if (ancestor !== undefined) {
            params.append('$ancestor', ancestor);
        }
        if (select !== undefined) {
            params.append('$select', select);
        }

        const url = params ? `assets?${params.toString()}` : 'assets';
        return axiosInstance.get<AssetCompleteDto[]>(url, {
            headers: {
                'x-api-version': apiVersion
            }
        });
    },
    getById: (id: number) => axiosInstance.get<AssetCompleteDto>(`assets/${id}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    create: (data: AssetDto[]) => axiosInstance.post<AssetDto[]>('assets', data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    update: (id: number, data: AssetDto) => axiosInstance.put<AssetDto>(`assets/${id}`, data, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    delete: (id: number) => axiosInstance.delete(`assets/${id}`, {
        headers: {
            'x-api-version': apiVersion
        }
    }),
    import:(body: FormData) => {
        return axiosInstance.post(`assets/import`, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-version': apiVersion
            }
        })
    }
}

const externalIdDefinitions = {
    getAll: () => {
        return axiosInstance.get<ExternalIdDefinitionDto[]>('externalIdDefinitions', {
            headers: {
                'x-api-version': apiVersion
            }
        })
    }
}

const services = { assets, externalIdDefinitions };

export { services, axiosInstance };
