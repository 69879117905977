import baseStyled, { ThemedStyledInterface } from "styled-components";

import { AssetTheme } from "../../themes/types";

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const ItemsTreeContainer = styled.div`
flex-grow: 1;
height: 0;
  .empty-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
