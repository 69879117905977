import { makeAutoObservable } from "mobx";
import { services } from "../api/agent";
import { ExternalIdDefinitionDto } from "../models/dtos/ExternalIdDefinitionDtos";

export default class ExternalIdDefinitionStore {
  externalIdDefinitions: ExternalIdDefinitionDto[] = [];
  definitionsUpdated: boolean = false;

  setExternalIdDefinitions = async () => {
      const response = await services.externalIdDefinitions.getAll();
      const responseData = response.data as ExternalIdDefinitionDto[];
      this.externalIdDefinitions = responseData;
  };

  setDefinitionsUpdated = (updatedFlag: boolean) => {
    this.definitionsUpdated = updatedFlag;
  };

  constructor() {
    makeAutoObservable(this);
  };
}