import { useEffect, useState } from "react";
import { services } from "../../../../../api/agent";
import { AssetCompleteDto } from "../../../../../models/dtos/AssetDtos";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";

const useAssetGeneralHook = (chosenAssetId: number) => {
  const { AssetStore } = useStore();
  const [updatedAsset, setUpdatedAsset] = useState<AssetCompleteDto>();
  const [initialAsset, setInitialAsset] = useState<AssetCompleteDto>();
  const [formKey, setFormKey] = useState(1);
  const { t } = useTranslation();

  // TODO provide select param to fetch only needed fields
  const fetchSingleAsset = async (id: number) => {
    const singleAsset = await services.assets.getById(id);
    const singleAssetData = singleAsset.data;
    setUpdatedAsset(singleAssetData);
    setInitialAsset(singleAssetData);
  };

  useEffect(() => {
    fetchSingleAsset(chosenAssetId);
  }, [chosenAssetId]);

  const onUpdateAsset = async (updatedAssetComplete: AssetCompleteDto) => {
    const { id, ...updatedAsset } = updatedAssetComplete;
    await services.assets
      .update(id, updatedAsset)
      .then((response) => {
        notification.success({
          message: t(
            "asset-overview.tab.general.notification.title.success"
          ),
        });
        const updateResponseData = response.data as AssetCompleteDto;
        AssetStore.setTreeUpdated(true);
        setInitialAsset(updateResponseData);
      })
      .catch((e) =>
        notification.error({
          message: t(
            "asset-overview.tab.general.notification.title.failed"
          ),
        })
      )
      .finally(() => {});
  };

  const onCancelChanges = () => {
    setFormKey(formKey + 1);
    setUpdatedAsset(initialAsset);
  };

  const handleSetUpdatedAsset = (data: Partial<AssetCompleteDto>) => {
    setUpdatedAsset(
      (prevState) =>
        ({
          ...prevState,
          ...data,
        }) as AssetCompleteDto
    );
  };

  return {
    onUpdateAsset,
    updatedAsset,
    handleSetUpdatedAsset,
    onCancelChanges,
    initialAsset,
    formKey,
  };
};

export default useAssetGeneralHook;
