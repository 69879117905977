import React from "react";
import * as styled from "./ImportModal.styles";
import FormField from "@plasma/ui.input.form-field";
import { Form } from "@plasma/ui.input.form";
import Button from "@plasma/ui.general.button";
import { InboxOutlined } from "@ant-design/icons";
import useImportModalHook from "./ImportModal.hook";
import { Trans, useTranslation } from "react-i18next";
import { Select, Upload } from "antd";
import Modal from "@plasma/ui.layout.modal";
import { useStore } from "../../../../../stores/store";

interface Props {
  open: boolean;
  onCancel: () => void;
}

export default function ImportModal(props: Props) {
  const { t } = useTranslation();

  const Content = () => {
    const { AssetStore } = useStore();
    const logic = useImportModalHook();

    const { Dragger } = Upload;
    return (
      <styled.ImportModal>
        <div className="parent-area">
          <Form>
            <FormField 
              required 
              label={t("import-asset.parent")} 
              name="parent"
              hint={t("import-asset.hint.parent")}
            >
              <Select
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                allowClear
                showSearch
                onClear={()=>logic.selectParentHandler(undefined)}
                onSelect={logic.selectParentHandler}
                options={AssetStore.parentSelectOptions}
                className="field"
                showArrow
              />
            </FormField>
          </Form>
        </div>
        <div className="upload-area">
          {}
          <Dragger
            maxCount={1}
            fileList={logic.pbsFile?[logic.setFileStatus(logic.pbsFile)]:[]}
            onRemove={()=>logic.handlePBSFileUpload (null)}
            beforeUpload={logic.handlePBSFileUpload}
            className={`upload ${logic.error ? "error" : ""}`}
            type="drag"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <Trans
                i18nKey={"import-asset.upload.title"}
                values={{ format: "PBS" }}
              />
            </p>
          </Dragger>
          <Dragger
            maxCount={1}
            fileList={logic.pspFile?[logic.setFileStatus(logic.pspFile)]:[]}
            onRemove={()=>logic.handlePSPFileUpload(null)}
            beforeUpload={logic.handlePSPFileUpload}
            className={`upload ${logic.error ? "error" : ""}`}
            type="drag"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <Trans
                i18nKey={"import-asset.upload.title"}
                values={{ format: "PSP" }}
              />
            </p>
          </Dragger>
        </div>
        <span className="error-msg">
          {logic.error ? "Upload not successfull !" : ""}
        </span>
        <div className="btn-container">
          <Button
            title="button.cancel"
            type="secondary"
            onClick={props.onCancel}
          />
          <Button
            disabled={!logic.allowUpload}
            loading={logic.loading}
            title="button.upload"
            type="primary"
            onClick={logic.handleUpload}
          />
        </div>
      </styled.ImportModal>
    );
  };
  return (
    <Modal
      onCancel={props.onCancel}
      width={"auto"}
      title={t("import-asset.title")}
      centered={true}
      destroyOnClose={true}
      visible={props.open}
      footer={null}
    >
      <Content />
    </Modal>
  );
}
