import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { OverviewTheme } from './themes/types';

const styled = baseStyled as ThemedStyledInterface<OverviewTheme>;

export const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: auto;
`;

export const GlobalControlsContainer = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #D9D9D9;
`;

export const ItemsOverviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;
