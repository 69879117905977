import React from "react";
import Empty from "@plasma/ui.display.empty";
import { ItemsTreeContainer } from "./customTree.styles";
import useCustomTreeHook from "./customTree.hook";
import SimpleTable from "../../../../shared/ListTable/ListTable";
import SmsSpin from "../../../../core/SmsSpin/SmsSpin";
import { useTranslation } from "react-i18next";
import AssetDeleteModal from "../../asset-delete-modal/assetDeleteModal";
import { useStore } from "../../../../../stores/store";

interface Props {
  searchValue: string;
  selectedRows: Record<string, boolean> | undefined;
  setSelectedRows: any;
  selectedColumns: {
    title: string;
    dataIndex: string;
  }[];
}

function CustomTree(props: Props) {
  const { AssetStore } = useStore();
  const { t } = useTranslation();
  const logic = useCustomTreeHook(props.searchValue);
  const selectedElement = Object.keys(props.selectedRows as any)[0];
  
  return (
    <ItemsTreeContainer>
      {logic.initialLoading ? (
        <SmsSpin />
      ) : logic.assetHierarchy?.length > 0 ? (
        <SimpleTable
          onDelete={()=>logic.setShowAssetDeleteModal(true)}
          columns={props.selectedColumns}
          items={logic.assetHierarchy}
          onExpand={logic.onExpandAsset}
          selectedElement={
            selectedElement ? parseInt(selectedElement) : undefined
          }
          onSelect={(id: any) => {
            props.setSelectedRows(id);
          }}
        />
      ) : (
        <div className="empty-container">
          <Empty
            message={t("asset-tree.empty.message.noData")}
            icon="StackedBarChart"
          />
        </div>
      )}
      <AssetDeleteModal
        deleteAssetModalOpened={logic.showAssetDeleteModal}
        setDeleteAssetModalOpened={logic.setShowAssetDeleteModal}
        chosenAsset={AssetStore.selectedAsset!}
        onDelete={() => AssetStore.setSelectedAsset(undefined)}
      />
    </ItemsTreeContainer>
  );
}

export default CustomTree;
