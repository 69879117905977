import React from 'react';

import { useHelpBundle } from '@plasma/ui.application.app';
import { useResourceBundle, useTranslation } from '@plasma/ui.utils.i18n';

import translations from './i18n';
import help from './layout.help';

function useLayoutHook() {
  useResourceBundle(translations, undefined, '1050a693-e6b5-4e7b-9a55-414e51e83a01');
  useHelpBundle(help);

  const { t } = useTranslation();

  return {
    t,
  };
}

export default useLayoutHook;
