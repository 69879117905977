import React from 'react';

import { Navigate, Route, RouteObject } from '@plasma/ui.application.router';

import { LayoutRoutes } from './components/layouts/layout';

export const routes: RouteObject[] = [
  new Route({
    path: '/',
    element: () => <Navigate to="/assets" replace />,
  }),
  LayoutRoutes,
];
