import { useState } from "react";

const useMultipleAssetViewHook = () => {
  const [createAssetModalOpened, setCreateAssetModalOpened] =
    useState<boolean>(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  return {
    searchValue,
    setSearchValue,
    createAssetModalOpened,
    setCreateAssetModalOpened,
    showImportModal,
    setShowImportModal,
  };
};

export default useMultipleAssetViewHook;
