import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import Icon from '@plasma/ui.general.icon'

import { AssetTheme } from '../../themes/types';

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const AssetHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 48px;
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  padding: 16px;

justify-content: space-between;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;