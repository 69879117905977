import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .svg-icon {
  }
  @keyframes spinRight {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  circle:nth-child(1) {
    animation: spinRight 4s linear infinite;
    transform-origin: center;
  }

  circle:nth-child(3) {
    animation: spinRight 2s linear infinite;
    transform-origin: center;
  }

  @keyframes spinLeft {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
  circle:nth-child(2) {
    animation: spinLeft 3s linear infinite;
    transform-origin: center;
  }

  .loading-message {
    font-weight: 400;
    font-size: 20px;
  }
`;
