import React from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";

import themes from "./themes";
import {
  MainContainer,
  ItemsOverviewContainer,
} from "./overview.styles";
import AssetOverview from "../../components/features/asset/asset-overview/assetOverview";
import useOverviewHook from "./overview.hook";
import MultipleAssetView from "../../components/features/asset/multiple-assets-view/multipleAssetView";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";

function Overview(props: Readonly<ThemeOverride>) {
  const { size, themeMode } = props;
  const { AssetStore } = useStore();
  useOverviewHook();

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <MainContainer>
        <ItemsOverviewContainer>
          <MultipleAssetView
            selectedRows={AssetStore.selectedAsset?{[AssetStore.selectedAsset?.id]:true}:{}}
            setSelectedRows={AssetStore.setSelectedAsset}
          />
          <AssetOverview
            selectedRows={AssetStore.selectedAsset?{[AssetStore.selectedAsset?.id]:true}:{}}
          />
        </ItemsOverviewContainer>
      </MainContainer>
    </ThemeProvider>
  );
}

export default observer(Overview);
