// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  height: 100vh;

  .edit-switch {
    span{
      color: #FFFFFF
    }

  }
}

.ant-notification-notice{
  font-family: 'Roboto';
  font-style: normal;
  color: #393939;
  .ant-notification-notice-close{
    color: #393939;
  }

  border-left: 3px solid;
  .ant-notification-notice-message{
   font-weight: 500;
  }
  .ant-notification-notice-close:hover{
    /* color:red; */
  }
  .ant-notification-notice-description{
    /* color:red; */
  }
  &.ant-notification-notice-warning {
    background-color: #FFFCD5;
    border: 1px solid #FFCC99;
    border-left: 3px solid #FF9933;
  }
  &.ant-notification-notice-error {
    background-color: #F9D5D5;
    border: 1px solid #EC8081;
    border-left: 3px solid #E10814;
  }
  &.ant-notification-notice-success {
    background-color: #E1F9DC;
    border: 1px solid #A4EC95;
    border-left: 3px solid #48D82B;
  }
  &.ant-notification-notice-info {
    background-color: #DCE9F9;
    border: 1px solid #95BEEC;
    border-left: 3px solid #0775BC;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb;IACE;MACE;IACF;;EAEF;AACF;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;EACd;IACE,cAAc;EAChB;;EAEA,sBAAsB;EACtB;GACC,gBAAgB;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,8BAA8B;EAChC;EACA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,8BAA8B;EAChC;EACA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,8BAA8B;EAChC;EACA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,8BAA8B;EAChC;;AAEF","sourcesContent":["#root {\n  height: 100vh;\n\n  .edit-switch {\n    span{\n      color: #FFFFFF\n    }\n\n  }\n}\n\n.ant-notification-notice{\n  font-family: 'Roboto';\n  font-style: normal;\n  color: #393939;\n  .ant-notification-notice-close{\n    color: #393939;\n  }\n\n  border-left: 3px solid;\n  .ant-notification-notice-message{\n   font-weight: 500;\n  }\n  .ant-notification-notice-close:hover{\n    /* color:red; */\n  }\n  .ant-notification-notice-description{\n    /* color:red; */\n  }\n  &.ant-notification-notice-warning {\n    background-color: #FFFCD5;\n    border: 1px solid #FFCC99;\n    border-left: 3px solid #FF9933;\n  }\n  &.ant-notification-notice-error {\n    background-color: #F9D5D5;\n    border: 1px solid #EC8081;\n    border-left: 3px solid #E10814;\n  }\n  &.ant-notification-notice-success {\n    background-color: #E1F9DC;\n    border: 1px solid #A4EC95;\n    border-left: 3px solid #48D82B;\n  }\n  &.ant-notification-notice-info {\n    background-color: #DCE9F9;\n    border: 1px solid #95BEEC;\n    border-left: 3px solid #0775BC;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
