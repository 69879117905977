import { Dispatch, useState } from "react";
import React from "react";
import { AssetCompleteDto } from "../../../../models/dtos/AssetDtos";
import { checkObjectsAreEqual } from "../../../../utils/objectsEqualityChecker";
import { services } from "../../../../api/agent";
import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useStore } from "../../../../stores/store";

interface Props {
  chosenAsset: AssetCompleteDto;
  deleteAssetModalOpened: boolean;
  setDeleteAssetModalOpened: Dispatch<boolean>;
  onDelete: () => void;
}

const useAssetDeleteModalHook = (props: Props) => {
  const { AssetStore } = useStore();
  const [formInputValid, setFormInputValid] = useState<boolean>(false);
  const { t } = useTranslation();
  const validateInput = (inputData: string) => {
    const inputValid = checkObjectsAreEqual(inputData, props.chosenAsset.name);
    setFormInputValid(inputValid);
  };

  const handleOnDelete = async () => {
    await services.assets
      .delete(props.chosenAsset.id)
      .then(() => {
        props.onDelete();
        AssetStore.setTreeUpdated(true);
        props.setDeleteAssetModalOpened(false);
        notificationant.success({
          message: t("asset-overview.delete.notification.title.success"),
          description: (
            <Trans
              i18nKey={"asset-overview.delete.notification.message.success"}
              values={{ name: props.chosenAsset.name }}
              components={{ strong: <strong /> }}
            />
          ),
        });
      })
      .catch(() =>
        notificationant.error({
          message: t("asset-overview.delete.notification.title.failed"),
        })
      );
  };

  return {
    formInputValid,
    setFormInputValid,
    validateInput,
    handleOnDelete,
  };
};

export default useAssetDeleteModalHook;
