import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import {Icon} from '@plasma/ui.general.icon'

import { AssetTheme } from '../themes/types';

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const AssetDeleteModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px; 
`;

export const DeleteIcon = styled(Icon)`
  && {
    font-size: 22px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
justify-content: flex-end;
`

export const WarningMessage = styled.div`
  color: #E10814;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

