import React, { Dispatch, useState } from "react";

import Modal from "@plasma/ui.layout.modal";
import { Icon } from "@plasma/ui.general.icon";
import Button from "@plasma/ui.general.button";

import {
  AssetDeleteModalHeaderContainer,
  ButtonContainer,
  ModalContent,
  ModalHeader,
  WarningMessage,
} from "./assetDeleteModal.style";
import TextInput from "@plasma/ui.input.text-input";
import { AssetCompleteDto } from "../../../../models/dtos/AssetDtos";
import useAssetDeleteModalHook from "./assetDeleteModal.hook";
import { Trans, useTranslation } from "react-i18next";


interface Props {
  chosenAsset: AssetCompleteDto;
  deleteAssetModalOpened: boolean;
  setDeleteAssetModalOpened: Dispatch<boolean>;
  onDelete: ()=>void
}

function AssetDeleteModal(props: Props) {

  const { t } = useTranslation();

  const Content = () => {
    const logic = useAssetDeleteModalHook(props);

    return (
      <ModalContent>
        <p>
          <Trans
            values={{ name: props.chosenAsset?.name }}
            i18nKey={"asset-overview.delete.info-message"}
            components={{ strong: <strong /> }}
          />
        </p>
        <TextInput
          required
          onChange={(event) => {
            logic.validateInput(event.target.value);
          }}
        />
        {logic.formInputValid ? (
          <WarningMessage>
            <Trans
              values={{ name: props.chosenAsset?.name }}
              i18nKey={"asset-overview.delete.confirm-message"}
              components={{ strong: <strong /> }}
            />
          </WarningMessage>
        ) : null}
        <ButtonContainer>
          <Button.Cancel
            title={t("button.cancel")}
            type="secondary"
            onClick={() => props.setDeleteAssetModalOpened(false)}
          />
          {logic.formInputValid ? (
            <>
              <Button
                title={t("button.delete")}
                onClick={logic.handleOnDelete}
              />
            </>
          ) : (
            <>
              <Button title={t("button.delete")} disabled />
            </>
          )}
        </ButtonContainer>
      </ModalContent>
    );
  };
  
  return (
    <Modal
      footer={null}
      title={
        <AssetDeleteModalHeaderContainer>
          <Icon name="delete" variant="filled" style={{ color: "red" }} />
          <ModalHeader>{t("asset-overview.delete.title")}</ModalHeader>
        </AssetDeleteModalHeaderContainer>
      }
      centered={true}
      destroyOnClose={true}
      visible={props.deleteAssetModalOpened}
      onCancel={() => props.setDeleteAssetModalOpened(false)}
    >
      <Content />
    </Modal>
  );
}

export default AssetDeleteModal;
