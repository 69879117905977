import { useState } from "react"

const useAssetHeaderHook = () => {
    const [deleteAssetModalOpened, setDeleteAssetModalOpened] = useState<boolean>(false);

    return {
        deleteAssetModalOpened,
        setDeleteAssetModalOpened
    };
}

export default useAssetHeaderHook;