import { AssetCompleteDtoParams, AssetDtoParams } from "../types/Asset";

export class AssetDto {
    name: string;
    code: string;
    path: string;
    description?: string;
    externalIds?: { [key: string]: string | null };
  
    constructor({ name, code, path, description, externalIds }: AssetDtoParams) {
      this.name = name;
      this.code = code;
      this.path = path;
      this.description = description;
      this.externalIds = externalIds;
    }

  }
  
export class AssetCompleteDto extends AssetDto {
    id: number;
  
    constructor({ id, name, code, path, description, externalIds }: AssetCompleteDtoParams) {
      super({ name, code, path, description, externalIds });
      this.id = id;
    }
  }