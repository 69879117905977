import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { AssetTheme } from '../../themes/types';

const styled = baseStyled as ThemedStyledInterface<AssetTheme>;

export const AssetGeneralContainer = styled.div`
  padding: 16px;
  min-width: 480px;
  width: 35%;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`
