import React from "react";
import Button from "@plasma/ui.general.button";
import { AssetHeaderContainer, Label } from "./assetHeader.style";
import useAssetHeaderHook from "./assetHeader.hook";
import AssetDeleteModal from "../../asset-delete-modal/assetDeleteModal";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";
import { observer } from "mobx-react-lite";
import Secure from "@plasma/ui.application.secure"

function AssetHeader() {
  const { AssetStore } = useStore();
  const logic = useAssetHeaderHook();
  const { t } = useTranslation();
  
  return (
    <AssetHeaderContainer>
      <Label>
        <span>{AssetStore.selectedAsset?.name}</span>
      </Label>
      <Secure permissions={['editor']}>
      <Button
        type="secondary"
        onClick={() => {
          logic.setDeleteAssetModalOpened(true);
        }}
        prefixIcon={{ name: "delete", variant: "filled" }}
        title={t("button.delete")}
      />
      </Secure>
      <AssetDeleteModal
        deleteAssetModalOpened={logic.deleteAssetModalOpened}
        setDeleteAssetModalOpened={logic.setDeleteAssetModalOpened}
        chosenAsset={AssetStore.selectedAsset!}
        onDelete={()=>AssetStore.setSelectedAsset(undefined)}
      />
    </AssetHeaderContainer>
  );
}

export default observer(AssetHeader);
